<template></template>

<script setup lang="ts">
  import { defineColumnProps } from "../../lib/defines";

  defineExpose(
    Object.assign(
      {},
      defineColumnProps({
        showFilterMenu: false,
      }),
    ),
  );
</script>

